import { render, staticRenderFns } from "./game_record.vue?vue&type=template&id=44346051&scoped=true&"
import script from "./game_record.vue?vue&type=script&lang=js&"
export * from "./game_record.vue?vue&type=script&lang=js&"
import style0 from "./game_record.vue?vue&type=style&index=0&id=44346051&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "44346051",
  null
  
)

export default component.exports